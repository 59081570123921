import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata } from "@/utils/metadata";
import { buildClient } from "./utils";
const projectModule = socioGrpcClient.ssp_back.projects;

export const siteInformationClient = {
  ...buildClient(
    projectModule,
    projectModule.SiteInformationControllerPromiseClient,
    {
      createRequest: projectModule.SiteInformationRequest,
      updateRequest: projectModule.SiteInformationRequest,
    }
  ),
  getSiteInformationWithPlans: async (form = {}, filters = {}) => {
    const metadata = {
      ...buildMetadata({
        filters,
      }),
    };
    const request =
      new projectModule.SiteInformationGetSiteInformationWithPlansRequest(form);
    const response =
      await projectModule.SiteInformationControllerPromiseClient.getSiteInformationWithPlans(
        request,
        metadata
      );
    return response.toObject();
  },
};
export const sectionClient = buildClient(
  projectModule,
  projectModule.SectionControllerPromiseClient,
  {
    createRequest: projectModule.SectionRequest,
    updateRequest: projectModule.SectionRequest,
    destroyRequest: projectModule.SectionRequest,
    listRequest: projectModule.SectionListRequest,
  }
);
export const cadasterClient = buildClient(
  projectModule,
  projectModule.CadasterControllerPromiseClient,
  {
    createRequest: projectModule.CadasterRequest,
    updateRequest: projectModule.CadasterRequest,
    destroyRequest: projectModule.CadasterRequest,
    listRequest: projectModule.CadasterListRequest,
  }
);
