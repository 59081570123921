import socioGrpcClient from "@/setup/socioGrpcClient";
import Mission from "@/models/Mission";
import Parcel from "@/models/Parcel";
import { responseTojavascript } from "@/utils/utilsGrcpRest";
import { sspMetadata } from "@/utils/metadata";
import Dashboard from "@/models/Dashboard";

const MISSION_KEYS_MAP = {
  elementaryMissionsList: "elementaryMissions",
  parcelList: "parcel",
};
const state = {
  currentMissionUuid: null,
};

const getters = {
  currentMissionUuid: (state) => {
    return state.currentMissionUuid;
  },

  currentMission: (state) => {
    return (
      Mission.query()
        .where("uuid", state.currentMissionUuid)
        .with("manager")
        .with("case")
        .first() || {}
    );
  },

  currentMissionGedId: (state) => {
    const mission =
      Mission.query()
        .where("uuid", state.currentMissionUuid)
        .with("manager")
        .with("case")
        .first() || {};

    return mission.originId;
  },

  currentDashboard: () => {
    return (
      Dashboard.query().where("project", state.currentMissionUuid).first() || {}
    );
  },
};

const insertProjectData = (project) => {
  const { parcel = [] } = project;
  Parcel.create({
    data: parcel,
  });
};

const actions = {
  async retrieveMission({ commit }, uuid) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.ProjectRetrieveRequest,
      { uuid }
    );
    const response =
      await socioGrpcClient.ssp_back.projects.ProjectControllerPromiseClient.retrieve(
        request,
        {}
      );
    commit("SET_CURRENT_MISSION", uuid);
    const { data } = responseTojavascript(response, MISSION_KEYS_MAP);
    insertProjectData(data);
    await Mission.insertOrUpdate({ data: data });
  },
  async updateMission(_, missionData) {
    const excludeFields = ["id", "$id", "parcel"];
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.ProjectRequest,
      missionData,
      excludeFields
    );
    const response =
      await socioGrpcClient.ssp_back.projects.ProjectControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    const { data } = responseTojavascript(response, MISSION_KEYS_MAP);
    await Mission.insertOrUpdate({ data: data });
    insertProjectData(data);
    return data;
  },

  async retrieveMissionDashboard(_, uuid) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.DashboardRetrieveRequest,
      { uuid }
    );
    const response =
      await socioGrpcClient.ssp_back.projects.DashboardControllerPromiseClient.retrieve(
        request,
        {}
      );
    await Dashboard.create({ data: response.toObject() });
  },
  async updateDashboard(_, dashboardData) {
    const excludeFields = ["$id"];
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.DashboardRequest,
      dashboardData,
      excludeFields
    );

    const response =
      await socioGrpcClient.ssp_back.projects.DashboardControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    const { data } = responseTojavascript(response, MISSION_KEYS_MAP);
    await Dashboard.insertOrUpdate({ data: data });
  },
  async resetMission({ getters }) {
    const mission = getters.currentMissionUuid;
    const request = new socioGrpcClient.ssp_back.projects.ResetProjectRequest();
    request.setUuid(mission);
    const resp =
      await socioGrpcClient.ssp_back.projects.ProjectControllerPromiseClient.resetProject(
        request,
        {}
      );
    return resp.toObject();
  },
  async mappingsDashboardElementaryMissions(
    { getters, dispatch },
    oldElementaryMissions
  ) {
    const currentDashboard = getters.currentDashboard;
    const addedElementaryMissions =
      getters.currentMission.elementaryMissions.filter(
        (item) => !oldElementaryMissions.includes(item)
      );
    if (addedElementaryMissions.length === 0) {
      return;
    }
    const elementaryMissionsMappings = {
      A200: ["isGroundInvestigated"],
      A210: ["isUndergroundWaterInvestigated"],
      A220: ["isSuperficialWaterInvestigated", "isSedimentInvestigated"],
      A230: ["isGasInvestigated"],
      A240: ["isAmbientAirInvestigated"],
      A250: ["isFoodWaterInvestigated", "isVegetalInvestigated"],
      A260: ["isLandToExcavateInvestigated"],
    };
    addedElementaryMissions.forEach((elementaryMission) => {
      if (elementaryMissionsMappings[elementaryMission]) {
        elementaryMissionsMappings[elementaryMission].forEach((key) => {
          currentDashboard[key] = true;
        });
      }
    });
    try {
      await dispatch("updateDashboard", currentDashboard);
    } catch (error) {
      console.error("Error updating the dashboard:", error);
    }
  },
};

const mutations = {
  SET_CURRENT_MISSION: (state, missionUUid) => {
    state.currentMissionUuid = missionUUid;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
