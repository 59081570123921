import socioGrpcClient from "@/setup/socioGrpcClient";
import CalibrationsVerification from "@/models/CalibrationsVerification";
import { buildMetadata } from "@/utils/metadata";

const state = {
  currentPreparation: null,
};

const getters = {
  getCalibrationsVerification: (state, getters, rootState, rootGetters) => {
    const currentMission = rootGetters["missions/currentMission"];
    return CalibrationsVerification.query()
      .where("project", currentMission.uuid)
      .orderBy("date", "desc")
      .get();
  },
};

const actions = {
  async fetchCalibrationsVerification(_, metadata = {}) {
    const request =
      new socioGrpcClient.ssp_back.intervention.CalibrationsVerificationListRequest();

    const response =
      await socioGrpcClient.ssp_back.intervention.CalibrationsVerificationControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    await CalibrationsVerification.insert({
      data: response.toObject().resultsList,
    });

    return response.toObject().resultsList;
  },

  async updateCalibrationsVerification(_, data) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.CalibrationsVerificationRequest,
      data
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.CalibrationsVerificationControllerPromiseClient.update(
        request,
        {}
      );
    const updatedCalibrationsVerification = response.toObject();

    const dataInserted = await CalibrationsVerification.update({
      where: updatedCalibrationsVerification.uuid,
      data: updatedCalibrationsVerification,
    });

    return dataInserted;
  },
  async createCalibrationsVerification(_, data) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.CalibrationsVerificationRequest,
      data
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.CalibrationsVerificationControllerPromiseClient.create(
        request,
        {}
      );
    const updatedCalibrationsVerification = response.toObject();

    const dataInserted = await CalibrationsVerification.insert({
      data: updatedCalibrationsVerification,
    });
    return dataInserted;
  },
  async deleteCalibrationsVerification(_, CalibrationsVerificationUuid) {
    const request =
      new socioGrpcClient.ssp_back.intervention.CalibrationsVerificationDestroyRequest();

    request.setUuid(CalibrationsVerificationUuid);

    await socioGrpcClient.ssp_back.intervention.CalibrationsVerificationControllerPromiseClient.destroy(
      request,
      {}
    );
    await CalibrationsVerification.delete(CalibrationsVerificationUuid);
  },
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
