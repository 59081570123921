import socioGrpcClient from "@/setup/socioGrpcClient";
import MissionArchive from "@/models/MissionArchive";
import { buildMetadata, sspMetadata } from "@/utils/metadata";

const state = {
  currentPreparation: null,
};

const getters = {
  currentMissionArchive: (state, getters, rootState, rootGetters) => {
    const currentMission = rootGetters["missions/currentMission"];
    const archiveData = MissionArchive.query()
      .where("project", currentMission.uuid)
      .first();
    return archiveData;
  },
};

const actions = {
  async retrieveMissionArchive(_, metadata = {}) {
    const request =
      new socioGrpcClient.ssp_back.projects.MissionArchiveListRequest();

    const response =
      await socioGrpcClient.ssp_back.projects.MissionArchiveControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    await MissionArchive.insert({
      data: response.toObject().resultsList,
    });
    return response.toObject().resultsList;
  },
  async createMissionArchive(_, data) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.MissionArchiveRequest,
      data
    );
    const response =
      await socioGrpcClient.ssp_back.projects.MissionArchiveControllerPromiseClient.create(
        request,
        sspMetadata()
      );

    await MissionArchive.insert({
      data: response.toObject(),
    });
  },
  async updateMissionArchive(_, data) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.MissionArchiveRequest,
      data
    );
    const response =
      await socioGrpcClient.ssp_back.projects.MissionArchiveControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    const updatedMissionArchive = response.toObject();

    const missionArchiveData = await MissionArchive.update({
      where: updatedMissionArchive.uuid,
      data: updatedMissionArchive,
    });

    return missionArchiveData;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
