import SiteInformation from "@/models/SiteInformation";
import Cadaster from "@/models/Cadaster";
import Section from "@/models/Section";
import store from "@/store";
import {
  siteInformationClient,
  sectionClient,
  cadasterClient,
} from "@/store/api/project";

const state = {
  sectionCount: 0,
};

const mutations = {
  SET_SECTION_COUNT(state, sectionCount) {
    state.sectionCount = sectionCount;
  },
};

const getters = {
  getCurrentSiteInformation: () => {
    return SiteInformation.query().first();
  },
  getSections: () => {
    return Section.query().get();
  },
  getSectionsCount(state) {
    return state.sectionCount;
  },
};

const actions = {
  async fetchCurrentSiteInformation({ dispatch }) {
    const projectId = store.getters["missions/currentMissionUuid"];
    const newSiteInformation =
      await siteInformationClient.getSiteInformationWithPlans([projectId]);
    if (!newSiteInformation) {
      await dispatch(
        "createOrUpdateCurrentSiteInformation",
        new SiteInformation()
      );
      return;
    }
    await SiteInformation.create({
      data: newSiteInformation,
    });
  },
  async fetchCadasters() {
    const { resultsList: cadasters } = await cadasterClient.list({
      project: store.getters["missions/currentMissionUuid"],
    });
    if (!cadasters.length) {
      console.log("No cadaster found");
      return null;
    }
    await Cadaster.create({
      data: cadasters,
    });
    return cadasters;
  },
  async fetchSections({ commit }, metadata) {
    const { resultsList: sections, count } = await sectionClient.list(
      {
        ...metadata.filters,
        project: store.getters["missions/currentMissionUuid"],
      },
      metadata.pagination
    );
    if (!sections.length) {
      console.log("No cadaster found");
      return null;
    }
    commit("SET_SECTION_COUNT", count);
    await Section.create({
      data: sections,
    });
  },
  async createOrUpdateCurrentSiteInformation(_, siteInformation) {
    siteInformation.project = store.getters["missions/currentMissionUuid"];
    if (!siteInformation.uuid || siteInformation.uuid.includes("$")) {
      const newSiteInformation = await siteInformationClient.create(
        siteInformation
      );
      if (newSiteInformation) {
        return await SiteInformation.create({
          data: newSiteInformation,
        });
      }
    }
    const updatedSiteInformation = await siteInformationClient.update(
      siteInformation
    );
    if (updatedSiteInformation) {
      return await SiteInformation.insert({
        data: updatedSiteInformation,
      });
    }
  },
  async createOrUpdateCurrentCadaster(_, cadaster) {
    cadaster.project = store.getters["missions/currentMissionUuid"];
    if (!cadaster.uuid || cadaster.uuid.includes("$")) {
      const newCadaster = await cadasterClient.create(cadaster);
      if (newCadaster) {
        await Cadaster.insert({
          data: newCadaster,
        });
        return newCadaster;
      }
    }
    const updatedCadaster = await cadasterClient.update(cadaster);
    if (updatedCadaster) {
      await Cadaster.insert({
        data: updatedCadaster,
      });
      return updatedCadaster;
    }
  },
  async createOrUpdateCurrentSection(_, section) {
    section.project = store.getters["missions/currentMissionUuid"];
    if (!section.uuid || section.uuid.includes("$")) {
      const newSection = await sectionClient.create(section);
      if (newSection) {
        return await Section.create({
          data: newSection,
        });
      }
    }
    const updatedSection = await sectionClient.update(section);
    if (updatedSection) {
      return await Section.create({
        data: updatedSection,
      });
    }
  },
  async deleteCurrentCadaster(_, uuid) {
    await cadasterClient.destroy(uuid);
    return await Cadaster.delete(uuid);
  },
  async deleteCurrentSection(_, uuid) {
    await sectionClient.destroy(uuid);
    return Section.delete(uuid);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
