import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata, sspMetadata } from "@/utils/metadata";
import { Client } from "@socotec.io/socio-vue-components";
import Mission from "@/models/Mission";
import SspCase from "@/models/Case";

const state = {};

const getters = {
  getCurrentCase: (state, getters, rootState, rootGetters) => {
    const caseNumber = rootGetters["missions/currentMission"].caseNumber;
    const currentCase = SspCase.query().where("caseNumber", caseNumber).first();
    return currentCase || {};
  },
};

const actions = {
  /**
   * Fetch case list
   * @param [_]
   * @param metadata
   * @returns {Promise<Case[]>}
   */
  async fetchMissionsAndCases(context, metadata) {
    const request =
      new socioGrpcClient.production_unit_lister.lister.FullProjectListRequest();
    const response =
      await socioGrpcClient.production_unit_lister.lister.FullProjectControllerPromiseClient.list(
        request,
        buildMetadata(metadata)
      );
    const responseObj = response.toObject();
    const results = responseObj.resultsList;
    results.forEach((result) => {
      Client.createFromListerData(result);
      SspCase.createFromListerData(result);
      Mission.createFromListerData(result);
    });
    return responseObj.count;
  },

  async retrieveCase(_, { caseNumber }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.CaseRetrieveByCaseNumberRequest,
      { caseNumber }
    );
    const response =
      await socioGrpcClient.ssp_back.projects.CaseControllerPromiseClient.retrieveByCaseNumber(
        request,
        {}
      );

    await SspCase.insertOrUpdate({
      data: response.toObject(),
    });
  },

  async updateCase(_, caseData) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.CaseRequest,
      caseData
    );

    const response =
      await socioGrpcClient.ssp_back.projects.CaseControllerPromiseClient.update(
        request,
        sspMetadata()
      );

    await SspCase.insertOrUpdate({
      data: response.toObject(),
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
