import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata } from "@/utils/metadata";
import OutdoorSpace from "@/models/OutdoorSpace";
import PollutedEnvironment from "@/models/PollutedEnvironment";
import Complaint from "@/models/Complaint";
import store from "@/store";
import PollutedExtraEnvironment from "@/models/PollutedExtraEnvironment";

const sspMetadata = (metadata = {}) => {
  const metadataCopy = buildMetadata(metadata);
  metadataCopy["filters"] = JSON.stringify({
    project: store.getters["missions/currentMission"].uuid,
  });
  return metadataCopy;
};
const state = {};

const mutations = {};

const getters = {
  getCurrentOutdoorSpace: () => {
    return OutdoorSpace.query().first();
  },
  getCurrentComplaint: () => {
    return Complaint.query().first();
  },
  getCurrentPollutedEnvironment: () => {
    return PollutedEnvironment.query().first() || new PollutedEnvironment();
  },
};

const actions = {
  async fetchCurrentOutDoorSpace({ dispatch }) {
    const request =
      new socioGrpcClient.ssp_back.projects.OutdoorSpaceListRequest();
    const response =
      await socioGrpcClient.ssp_back.projects.OutdoorSpaceControllerPromiseClient.list(
        request,
        sspMetadata()
      );
    if (response) {
      const { resultsList } = response.toObject();
      if (!resultsList.length) {
        const project = store.getters["missions/currentMissionUuid"];
        await dispatch(
          "createOrUpdateCurrentOutdoorSpace",
          new OutdoorSpace({ project })
        );
        return;
      }
      await OutdoorSpace.create({
        data: resultsList,
      });
    }
  },
  async fetchCurrentComplaint({ dispatch }) {
    const request =
      new socioGrpcClient.ssp_back.projects.ComplaintsListRequest();
    const response =
      await socioGrpcClient.ssp_back.projects.ComplaintControllerPromiseClient.list(
        request,
        sspMetadata()
      );
    if (response) {
      const { resultsList } = response.toObject();
      if (!resultsList.length) {
        const project = store.getters["missions/currentMissionUuid"];
        await dispatch(
          "createOrUpdateCurrentComplaint",
          new Complaint({ project })
        );
        return;
      }
      await Complaint.create({
        data: response.toObject().resultsList,
      });
    }
  },
  async fetchCurrentPollutedEnvironment({ dispatch }) {
    const request =
      new socioGrpcClient.ssp_back.projects.PollutedEnvironmentListRequest();
    const response =
      await socioGrpcClient.ssp_back.projects.PollutedEnvironmentControllerPromiseClient.list(
        request,
        sspMetadata()
      );
    if (response) {
      const { resultsList } = response.toObject();
      if (!resultsList.length) {
        const project = store.getters["missions/currentMissionUuid"];
        await dispatch(
          "createOrUpdateCurrentPollutedEnvironment",
          new PollutedEnvironment({ project })
        );
        return;
      }
      await PollutedEnvironment.create({
        data: response.toObject().resultsList,
      });
    }
  },
  async fetchExtraPollutedEnvironments() {
    const projectUuid = store.getters["missions/currentMissionUuid"];
    const request =
      new socioGrpcClient.ssp_back.projects.PollutedExtraEnvironmentsListRequest();
    request.project = projectUuid;

    const response =
      await socioGrpcClient.ssp_back.projects.PollutedExtraEnvironmentsControllerPromiseClient.list(
        request,
        sspMetadata()
      );
    const { resultsList } = response.toObject();
    if (response) {
      await PollutedExtraEnvironment.create({
        data: resultsList,
      });
      return resultsList;
    }
  },
  async createOrUpdateCurrentOutdoorSpace(_, outdoorSpace) {
    const projectUuid = store.getters["missions/currentMissionUuid"];
    let response = null;
    outdoorSpace.project = projectUuid;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.OutdoorSpaceRequest,
      outdoorSpace
    );
    if (!outdoorSpace.uuid || outdoorSpace.uuid.includes("$")) {
      response =
        await socioGrpcClient.ssp_back.projects.OutdoorSpaceControllerPromiseClient.create(
          request,
          sspMetadata()
        );
      if (response) {
        return await OutdoorSpace.create({
          data: response.toObject(),
        });
      }
    }
    response =
      await socioGrpcClient.ssp_back.projects.OutdoorSpaceControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    if (response) {
      return await OutdoorSpace.create({
        data: response.toObject(),
      });
    }
  },
  async createOrUpdateCurrentComplaint(_, complaint) {
    const projectUuid = store.getters["missions/currentMissionUuid"];
    let response = null;
    complaint.project = projectUuid;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.ComplaintsRequest,
      complaint
    );
    if (!complaint.uuid || complaint.uuid.includes("$")) {
      response =
        await socioGrpcClient.ssp_back.projects.ComplaintControllerPromiseClient.create(
          request,
          sspMetadata()
        );
      if (response) {
        return await Complaint.create({
          data: response.toObject(),
        });
      }
    }
    response =
      await socioGrpcClient.ssp_back.projects.ComplaintControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    if (response) {
      return await Complaint.create({
        data: response.toObject(),
      });
    }
  },
  async createOrUpdateCurrentPollutedEnvironment(_, pollutedEnvironment) {
    const projectUuid = store.getters["missions/currentMissionUuid"];
    let response = null;
    pollutedEnvironment.project = projectUuid;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.PollutedEnvironmentRequest,
      pollutedEnvironment
    );
    if (!pollutedEnvironment.uuid || pollutedEnvironment.uuid.includes("$")) {
      response =
        await socioGrpcClient.ssp_back.projects.PollutedEnvironmentControllerPromiseClient.create(
          request,
          sspMetadata()
        );
      if (response) {
        return await PollutedEnvironment.create({
          data: response.toObject(),
        });
      }
    }
    response =
      await socioGrpcClient.ssp_back.projects.PollutedEnvironmentControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    if (response) {
      return await PollutedEnvironment.create({
        data: response.toObject(),
      });
    }
  },
  async createOrUpdatePollutedExtraEnvironment(_, pollutedExtraEnvironment) {
    const projectUuid = store.getters["missions/currentMissionUuid"];
    let response = null;
    pollutedExtraEnvironment.project = projectUuid;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.projects.PollutedExtraEnvironmentsRequest,
      pollutedExtraEnvironment
    );
    if (
      !pollutedExtraEnvironment.uuid ||
      pollutedExtraEnvironment.uuid.includes("$")
    ) {
      response =
        await socioGrpcClient.ssp_back.projects.PollutedExtraEnvironmentsControllerPromiseClient.create(
          request,
          sspMetadata()
        );
      if (response) {
        await PollutedExtraEnvironment.create({
          data: response.toObject(),
        });
        return response.toObject();
      }
    }
    response =
      await socioGrpcClient.ssp_back.projects.PollutedExtraEnvironmentsControllerPromiseClient.update(
        request,
        sspMetadata()
      );
    if (response) {
      await PollutedExtraEnvironment.create({
        data: response.toObject(),
      });
      return response.toObject();
    }
  },
  async deleteCurrentPollutedExtraEnvironment(_, uuid) {
    const request =
      new socioGrpcClient.ssp_back.projects.PollutedExtraEnvironmentsRequest();
    request.setUuid(uuid);
    await socioGrpcClient.ssp_back.projects.PollutedExtraEnvironmentsControllerPromiseClient.destroy(
      request,
      sspMetadata()
    );
    return PollutedExtraEnvironment.delete(uuid);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
