import socioGrpcClient from "@/setup/socioGrpcClient";
import Preparation from "@/models/Preparation";
import { renameKeys, responseTojavascript } from "@/utils/utilsGrcpRest";
import { buildMetadata, sspMetadata } from "@/utils/metadata";
import { Agency } from "@socotec.io/socio-vue-components";
import PreparationWithAnalysis from "@/models/PreparationWithAnalysis";

const PREPARATION_FIELDS_TO_EXCLUDE = [
  "additionalsMaterial",
  "$id",
  "analysisData",
  "materialsData",
  "validatedMaterialsData",
];

const PREPARATION_KEYS_MAP = {
  analysisList: "analysis",
  materialsList: "materials",
  validatedMaterialsList: "validatedMaterials",
  project: "mission",
};

const state = {
  currentPreparationUuid: null,
};

const getters = {
  getCurrentPreparationUuid: (state) => state.currentPreparationUuid,
  getCurrentPreparation: (state) => {
    return (
      Preparation.query()
        .where("uuid", state.currentPreparationUuid)
        .with("validatedMaterials")
        .with("materials")
        .with("analysis")
        .withAllRecursive()
        .first() || {}
    );
  },
  getCurrentPreparationWithMaterialFiltered:
    (state) =>
    ({ form, closurefunc }) => {
      return (
        Preparation.query()
          .where("uuid", state.currentPreparationUuid)
          .with("materialsData", (query) => {
            query.where((material) => {
              if (form.agency?.name) {
                const agency = Agency.find(material.agencyUuid);
                return (
                  closurefunc(form, material) &&
                  agency.name
                    .toLowerCase()
                    .includes(form.agency.name.toLowerCase())
                );
              }
              return closurefunc(form, material);
            });
          })
          .withAllRecursive()
          .first() || {}
      );
    },
  getCurrentPreparationWithAdditionalMaterialFiltered:
    (state) =>
    ({ form, closurefunc }) => {
      return (
        Preparation.query()
          .where("uuid", state.currentPreparationUuid)
          .with("additionalsMaterial", (query) => {
            query.where((material) => {
              return closurefunc(form, material);
            });
          })
          .withAllRecursive()
          .first() || {}
      );
    },
};

const actions = {
  async retrievePreparationByMission({ commit }, missionUuid) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.PreparationListRequest,
      {}
    );
    const requestMetadata = {
      ...buildMetadata({
        filters: {
          project: missionUuid,
        },
      }),
    };
    const response =
      await socioGrpcClient.ssp_back.intervention.PreparationControllerPromiseClient.list(
        request,
        requestMetadata
      );

    const { data } = responseTojavascript(response, PREPARATION_KEYS_MAP);

    const preparationData = data[0];

    await Preparation.insert({
      data: preparationData,
    });

    commit("SET_CURRENT_PREPARATION_UUID", preparationData.uuid);
  },
  async retrievePreparationWithAnalysisSynthesis(_, preparationUuid) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention
        .PreparationGetPreparationWithAnalysisSynthesisRequest,
      { preparationUuid }
    );
    const response =
      await socioGrpcClient.ssp_back.intervention.PreparationControllerPromiseClient.getPreparationWithAnalysisSynthesis(
        request,
        sspMetadata()
      );
    const { data } = responseTojavascript(response);
    return new PreparationWithAnalysis(data);
  },
  async updatePreparation(_, preparation) {
    const keysMap = {
      mission: "project",
    };
    preparation = renameKeys(keysMap, preparation);

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.PreparationRequest,
      preparation,
      PREPARATION_FIELDS_TO_EXCLUDE
    );

    const response =
      await socioGrpcClient.ssp_back.intervention.PreparationControllerPromiseClient.update(
        request,
        sspMetadata()
      );

    const { data } = responseTojavascript(response, PREPARATION_KEYS_MAP);
    await Preparation.insertOrUpdate({
      where: data.uuid,
      data: data,
    });
  },

  async createPreparation({ commit }, preparation) {
    const keysMap = {
      mission: "project",
    };
    preparation = renameKeys(keysMap, preparation);

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.ssp_back.intervention.PreparationRequest,
      preparation
    );

    const response =
      await socioGrpcClient.ssp_back.intervention.PreparationControllerPromiseClient.create(
        request,
        sspMetadata()
      );
    const newPreparation = response.toObject();

    await Preparation.insert({
      data: {
        ...newPreparation,
      },
    });

    commit("SET_CURRENT_PREPARATION_UUID", newPreparation.uuid);
  },
};
const mutations = {
  SET_CURRENT_PREPARATION_UUID: (state, preparationUuid) => {
    state.currentPreparationUuid = preparationUuid;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
