import {
  siteClient,
  zoneClient,
  otherItemClient,
  componentClient,
  buildingClient,
} from "@/store/api/cartography";
import { filterExcludedFields } from "@/utils/arrayUtils";
import Marker from "@/models/cartography/Marker";
import { insertMarkers } from "@/models/cartography/utils";

import _ from "lodash";
const SITE = "SITE";
const BUILDING = "BUILDING";
const ZONE = "ZONE";
const COMPONENT = "COMPONENT";
const OTHER_ITEM = "OTHER_ITEM";

const SET_SITE_UUID = "SET_SITE_UUID";
const SET_IS_LOADING = "SET_IS_LOADING";
const SET_IS_UPDATING = "SET_IS_UPDATING";

const typeToClientMapping = {
  [BUILDING]: buildingClient,
  [ZONE]: zoneClient,
  [SITE]: siteClient,
  [OTHER_ITEM]: otherItemClient,
  [COMPONENT]: componentClient,
};

const excludeFields = ["createdAt", "updatedAt", "archiveDate"];

const state = {
  siteUuid: null,
  isLoading: false,
  isUpdating: false,
};

const getters = {
  centralMarker:
    (state) =>
    (value = "") => {
      let marker = Marker.query()
        .with("markable")
        .with("markers")
        .with("markers.markable")
        .find(state.siteUuid);
      if (marker) {
        marker.markers = marker.markers.filter((nestedMarker) => {
          return nestedMarker.markable?.label?.includes(value);
        });
      }
      return marker;
    },
};

const actions = {
  getCurrentSite: async ({ commit }, projectUuid) => {
    const { resultsList: sites } = await siteClient.list({
      project: projectUuid,
    });
    if (!sites.length) {
      commit(SET_IS_LOADING, false);
      console.log("No site found");
      return null;
    }
    // INFO - B.L - Project can be link to only one site
    let site = sites[0];
    commit(SET_SITE_UUID, site.uuid);
    return site;
  },

  fetchMarkers: async ({ commit, dispatch }, { projectUuid, metadata }) => {
    commit(SET_SITE_UUID, null);
    commit(SET_IS_LOADING, true);
    if (!projectUuid) {
      commit(SET_IS_LOADING, false);
      return null;
    }
    let site = await dispatch("getCurrentSite", projectUuid);
    if (!site) {
      commit(SET_IS_LOADING, false);
      return null;
    }
    insertMarkers(site);
    const filters = { site: site.uuid, is_geolocation_not_null: true };
    const markers = await Promise.all([
      zoneClient.list(filters),
      otherItemClient.list(filters),
      componentClient.list(filters),
      buildingClient.list(filters),
    ]);

    const resultsList = _.flatten(markers.map((el) => el.resultsList));
    const totalItems = resultsList.length;

    if (metadata) {
      const { page = 1, page_size: pageSize = 10 } = JSON.parse(
        metadata.pagination || "{}"
      );
      const paginatedResults = resultsList.slice(
        (page - 1) * pageSize,
        page * pageSize
      );
      insertMarkers(paginatedResults);
      commit(SET_IS_LOADING, false);
      return {
        items: paginatedResults,
        totalItems,
      };
    }

    insertMarkers(resultsList);
    commit(SET_IS_LOADING, false);
    return markers;
  },

  createMarker: async ({ commit }, { aosType, form }) => {
    commit(SET_IS_UPDATING, true);
    const client = typeToClientMapping[aosType];
    const response = await client.create(form);
    insertMarkers(response);
    commit(SET_IS_UPDATING, false);
    return Marker.query().with("markable").find(response.uuid);
  },

  updateMarker: async ({ commit }, form) => {
    commit(SET_IS_UPDATING, true);
    const filteredForm = filterExcludedFields(form, excludeFields);
    const client = typeToClientMapping[form.typology];
    const response = await client.partialUpdate(filteredForm);
    insertMarkers(response);
    commit(SET_IS_UPDATING, false);
    return Marker.query().with("markable").find(response.uuid);
  },

  deleteMarker: async ({ commit }, { type, uuid }) => {
    commit(SET_IS_UPDATING, true);
    const client = typeToClientMapping[type];
    await client.destroy(uuid);
    await Marker.delete(uuid);
    commit(SET_IS_UPDATING, false);
  },
  updateSiteCenter: async ({ commit }, form) => {
    commit(SET_IS_UPDATING, true);
    const client = siteClient;
    const site = await client.partialUpdate(form);
    insertMarkers(site);
    commit(SET_IS_UPDATING, false);
  },
};

const mutations = {
  [SET_SITE_UUID]: (state, siteUuid) => {
    state.siteUuid = siteUuid;
  },
  [SET_IS_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  [SET_IS_UPDATING]: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
